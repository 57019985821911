/* eslint-env browser */

//------------------------------------------------------------------------------
//             __             __   ___  __
//     | |\ | /  ` |    |  | |  \ |__  /__`
//     | | \| \__, |___ \__/ |__/ |___ .__/
//
//------------------------------------------------------------------------------
import React from "react";
import {Helmet} from "react-helmet-async";
import {useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {useInterval} from "@tzmedical/react-hooks";

import axios from "../../axiosClient.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const AverageStudyLengths = React.lazy(() => import(/* webpackPrefetch: true */ "./AverageStudyLength.jsx"));
const BasicStatCard = React.lazy(() => import(/* webpackPrefetch: true */ "./BasicStatCard.jsx"));
const StudiesOverTime = React.lazy(() => import(/* webpackPrefetch: true */ "./StudiesOverTime.jsx"));
const PieChartCard = React.lazy(() => import(/* webpackPrefetch: true */ "./PieChartCard.jsx"));

// refresh every 10 minutes seconds to keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 10 * 60 * 1000;

const FILTER_BUTTONS = [
  {
    name: "All Time",
    calculateStart: () => null,
    calculateEnd: (datetime) => datetime,
  },
  {
    name: "1 Year",
    calculateStart: (datetime) => datetime.minus({years: 1}),
    calculateEnd: (datetime) => datetime,
  },
  {
    name: "3 Months",
    calculateStart: (datetime) => datetime.minus({months: 3}),
    calculateEnd: (datetime) => datetime,
  },
  {
    name: "Last Month",
    calculateStart: (datetime) => datetime.minus({months: 1}).startOf("month"),
    calculateEnd: (datetime) => datetime.minus({months: 1}).endOf("month"),
  },
  {
    name: "30 Days",
    calculateStart: (datetime) => datetime.minus({days: 30}),
    calculateEnd: (datetime) => datetime,
  },
];

const dashboardPalette = ["#004c8b", "#58a5ef", "#3B684D", "#86B63E", "#73304C", "#C97E9D", "#78909c"];

//----------------------------------------------------------------------------
function countStudies(weekList, state, studyType) {
  if (!weekList.length) {
    return 0;
  }
  return weekList.reduce((acc, week) => {
    if (studyType) {
      return acc + week[state][studyType];
    }
    return Object.keys(week[state]).reduce((weekAcc, key) => weekAcc + week[state][key], acc);
  }, 0);
}

//----------------------------------------------------------------------------
export function allowDashboard(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "facilityAdmin"]);
}

//----------------------------------------------------------------------------
function Dashboard() {
  const {isInAnyRole, userFacilityId} = useJwt();
  const [searchParams, setSearchParams] = useSearchParams();
  const facilityId = React.useMemo(
    () => searchParams.get("facilityId") || (isInAnyRole(["tzAdmin"]) ? null : userFacilityId),
    [isInAnyRole, searchParams, userFacilityId]
  );

  //--------------------------------------------------------------------------
  // State Management
  //--------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [facilitiesLoading, setFacilitiesLoading] = React.useState(true);
  const [includeSubFacilityData, setIncludeSubFacilityData] = React.useState(false);
  const [facilitiesList, setFacilitiesList] = React.useState([{label: "All Facilities", id: null}]);
  const [error, setError] = React.useState(null);
  const [facilitiesError, setFacilitiesError] = React.useState(null);

  const [studiesDashboardData, setStudiesDashboardData] = React.useState([]);
  const [inboxItemsDashboardData, setInboxItemsDashboardData] = React.useState({});
  const [generatedReportCount, setGeneratedReportCount] = React.useState(0);
  const [urgentGeneratedReportCount, setUrgentGeneratedReportCount] = React.useState(0);

  //--------------------------------------------------------------------------
  // Facility Selection Interface
  //--------------------------------------------------------------------------
  const [selectedFacilityValue, setSelectedFacilityValue] = React.useState(facilitiesList[0]);
  const [defaultFacilityValue, setDefaultFacilityValue] = React.useState(facilitiesList[0]);
  const handleFacilityChange = React.useCallback(
    (event, newFacility) => {
      if (newFacility?.id) {
        setSelectedFacilityValue(newFacility);
        setSearchParams({facilityId: newFacility.id});
        setIncludeSubFacilityData(true);
      } else {
        setSelectedFacilityValue(facilitiesList[0]);
        setSearchParams();
        setIncludeSubFacilityData(false);
      }
      setLoading(true);
    },
    [facilitiesList, setSearchParams]
  );

  const handleCheckboxChange = React.useCallback((event, checked) => {
    setIncludeSubFacilityData(checked);
    setLoading(true);
  }, []);

  //--------------------------------------------------------------------------
  // Time Range Buttons
  //--------------------------------------------------------------------------
  const [timeFilterButtons, setTimeFilterButtons] = React.useState("3 Months");
  const [customDateSelected, setCustomDateSelected] = React.useState(false);
  const [endDate, setEndDate] = React.useState(DateTime.local());
  const [startDate, setStartDate] = React.useState(DateTime.local().minus({months: 3}));
  const handleTimeFilterButtonsChange = React.useCallback((event) => {
    setTimeFilterButtons(event.target.value);
    setCustomDateSelected(false);
    setLoading(true);

    setEndDate(FILTER_BUTTONS.find((el) => el.name === event.target.value)?.calculateEnd(DateTime.local()));
    setStartDate(
      FILTER_BUTTONS.find((el) => el.name === event.target.value)?.calculateStart(DateTime.local())
    );
  }, []);

  const handleCustomStartDateChange = React.useCallback((newStartDate) => {
    setStartDate(newStartDate);
    setCustomDateSelected(true);
    setLoading(true);
  }, []);

  const handleCustomEndDateChange = React.useCallback((newEndDate) => {
    setEndDate(newEndDate);
    setCustomDateSelected(true);
    setLoading(true);
  }, []);

  //--------------------------------------------------------------------------
  // DATA FETCHING
  //--------------------------------------------------------------------------
  const getFacilities = React.useCallback(async () => {
    try {
      const resFacilities = await axios({
        method: "get",
        url: "/facilities",
        params: {order: [["name", "ASC"]]},
      });

      const facilityOptions = [
        {label: "All Facilities", id: null},
        ...resFacilities.data.map((facility) => {
          return {label: facility.name, id: facility.id};
        }),
      ];

      const currentFacility = facilityOptions.find((el) => el.id === (facilityId || null));
      setFacilitiesList(facilityOptions);
      setDefaultFacilityValue(currentFacility);
      setSelectedFacilityValue(currentFacility);
      setFacilitiesLoading(false);
    } catch (err) {
      setFacilitiesError(
        err.response?.data?.message || err.response?.data?.error || err.response?.data?.title || err.message
      );
    }
  }, [facilityId]);

  const getDashboardData = React.useCallback(async () => {
    if (!facilitiesLoading) {
      try {
        const [{data: studiesData}, {data: inboxItemsData}, {data: generatedReportsData}] = await Promise.all(
          [
            axios({
              method: "get",
              url: "/dashboard/studies",
              params: {
                facilityId,
                includeSubFacilityData,
                startDate: startDate?.startOf("day").toISO(),
                endDate: endDate?.endOf("day").toISO(),
              },
            }),
            axios({
              method: "get",
              url: "/dashboard/inbox-items",
              params: {
                facilityId,
                includeSubFacilityData,
                startDate: startDate?.startOf("day").toISO(),
                endDate: endDate?.endOf("day").toISO(),
              },
            }),
            axios({
              method: "get",
              url: "/dashboard/generated-reports",
              params: {
                facilityId,
                includeSubFacilityData,
                startDate: startDate?.startOf("day").toISO(),
                endDate: endDate?.endOf("day").toISO(),
              },
            }),
          ]
        );

        setStudiesDashboardData(studiesData);
        setInboxItemsDashboardData(inboxItemsData);
        setGeneratedReportCount(Number(generatedReportsData.reportsSent));
        setUrgentGeneratedReportCount(Number(generatedReportsData.urgentReports));
        setLoading(false);
      } catch (err) {
        setError(
          err.response?.data?.message || err.response?.data?.error || err.response?.data?.title || err.message
        );
        setLoading(true);
      }
    }
  }, [endDate, facilitiesLoading, facilityId, includeSubFacilityData, startDate]);

  useInterval(getFacilities, DATA_REFRESH_INTERVAL_MS, facilitiesLoading && !facilitiesError);
  useInterval(getDashboardData, DATA_REFRESH_INTERVAL_MS, !facilitiesLoading && loading && !error && endDate);

  //--------------------------------------------------------------------------
  // ROUTER CHANGES
  //--------------------------------------------------------------------------
  React.useEffect(() => {
    setSelectedFacilityValue(facilitiesList.find((el) => el.id === (facilityId || null)));
    const defaultPeriod = "3 Months";
    setTimeFilterButtons(defaultPeriod);
    setCustomDateSelected(false);
    setEndDate(FILTER_BUTTONS.find((el) => el.name === defaultPeriod)?.calculateEnd(DateTime.local()));
    setStartDate(FILTER_BUTTONS.find((el) => el.name === defaultPeriod)?.calculateStart(DateTime.local()));
    setLoading(true);
    if (facilityId) {
      setIncludeSubFacilityData(true);
    } else {
      setIncludeSubFacilityData(false);
    }
  }, [facilitiesList, facilityId]);

  //--------------------------------------------------------------------------
  // DATA MARSHALLING
  //--------------------------------------------------------------------------
  const newStudiesOverTime = React.useMemo(() => {
    return studiesDashboardData.map((week) => ({
      time: DateTime.fromISO(week.start).toMillis(),
      ...week.started,
    }));
  }, [studiesDashboardData]);
  const newStudiesByType = React.useMemo(
    () => [
      {name: "MCT", count: countStudies(studiesDashboardData, "started", "mct")},
      {name: "CEM", count: countStudies(studiesDashboardData, "started", "cem")},
      {name: "Holter", count: countStudies(studiesDashboardData, "started", "holter")},
      {name: "Extended Holter", count: countStudies(studiesDashboardData, "started", "extendedHolter")},
      {name: "Other", count: countStudies(studiesDashboardData, "started", "other")},
    ],
    [studiesDashboardData]
  );
  const newStudiesTotal = React.useMemo(
    () => countStudies(studiesDashboardData, "started"),
    [studiesDashboardData]
  );

  const completedStudiesOverTime = React.useMemo(() => {
    return studiesDashboardData.map((week) => ({
      time: DateTime.fromISO(week.start).toMillis(),
      ...week.finalized,
    }));
  }, [studiesDashboardData]);
  const completedStudies = React.useMemo(
    () => [
      {name: "MCT", count: countStudies(studiesDashboardData, "finalized", "mct")},
      {name: "CEM", count: countStudies(studiesDashboardData, "finalized", "cem")},
      {name: "Holter", count: countStudies(studiesDashboardData, "finalized", "holter")},
      {name: "Extended Holter", count: countStudies(studiesDashboardData, "finalized", "extendedHolter")},
      {name: "Other", count: countStudies(studiesDashboardData, "finalized", "other")},
    ],
    [studiesDashboardData]
  );
  const completedStudyCount = React.useMemo(
    () => countStudies(studiesDashboardData, "finalized"),
    [studiesDashboardData]
  );

  const averageStudyLengths = React.useMemo(
    () => [
      {
        name: "All Types",
        "Average Length": Number(
          countStudies(studiesDashboardData, "totalHours") / (24 * completedStudyCount)
        ).toFixed(1),
      },
      {
        name: "MCT",
        "Average Length": Number(
          countStudies(studiesDashboardData, "totalHours", "mct") /
            (24 * completedStudies.find((el) => el.name === "MCT").count)
        ).toFixed(1),
      },
      {
        name: "CEM",
        "Average Length": Number(
          countStudies(studiesDashboardData, "totalHours", "cem") /
            (24 * completedStudies.find((el) => el.name === "CEM").count)
        ).toFixed(1),
      },
      {
        name: "Holter",
        "Average Length": Number(
          countStudies(studiesDashboardData, "totalHours", "holter") /
            (24 * completedStudies.find((el) => el.name === "Holter").count)
        ).toFixed(1),
      },
      {
        name: "Extended Holter",
        "Average Length": Number(
          countStudies(studiesDashboardData, "totalHours", "extendedHolter") /
            (24 * completedStudies.find((el) => el.name === "Extended Holter").count)
        ).toFixed(1),
      },
      {
        name: "Other",
        "Average Length": Number(
          countStudies(studiesDashboardData, "totalHours", "other") /
            (24 * completedStudies.find((el) => el.name === "Other").count)
        ).toFixed(1),
      },
    ],
    [completedStudies, completedStudyCount, studiesDashboardData]
  );

  //--------------------------------------------------------------------------
  // Print theming support
  //--------------------------------------------------------------------------
  const theme = useTheme();
  const documentTitle = React.useMemo(() => {
    if (!selectedFacilityValue?.label) {
      return "All Facilities Dashboard";
    }
    return `${selectedFacilityValue.label}${includeSubFacilityData ? "*" : ""} Dashboard`;
  }, [includeSubFacilityData, selectedFacilityValue?.label]);
  const mobile = useMediaQuery("(max-width:899px)") ? 1 : 0;

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowDashboard(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Container
        sx={{
          mb: 4,
          display: "none",
          "@media print": {display: "block"},
          alignItems: "center",
        }}
      >
        <Typography align="center" variant="h2" color="primary">
          {documentTitle}
        </Typography>
        <Typography align="center" variant="h5" color={theme.palette.grey[900]}>
          {`${startDate.toLocaleString()} - ${endDate.toLocaleString()}`}
        </Typography>
      </Container>
      <Card
        sx={{
          width: "100%",
          mb: 2,
          p: 1,
          backgroundColor: theme.palette.background.paper,
          "@media print": {
            display: "none",
          },
        }}
      >
        <CardContent>
          <Grid container rowSpacing={1} columnSpacing={10} sx={{alignItems: "center"}}>
            <Grid size={{xs: 12, md: 6}}>
              <Alert message={facilitiesError} setMessage={setFacilitiesError} level="error" />
              {facilitiesLoading ? (
                <TableLoading marginTop={1} />
              ) : (
                <Stack direction="column" spacing={1}>
                  <Autocomplete
                    id="category-select"
                    data-cy="dashboard-facility-select"
                    size="small"
                    value={selectedFacilityValue}
                    options={facilitiesList}
                    onChange={handleFacilityChange}
                    sx={{width: "100%"}}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Facility" />}
                    defaultValue={defaultFacilityValue}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={includeSubFacilityData} onChange={handleCheckboxChange} />}
                      label="Include Sub-facility Data"
                      disabled={!facilityId}
                    />
                  </FormGroup>
                </Stack>
              )}
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
              <Stack
                direction={mobile ? "row" : "column"}
                spacing={1}
                sx={{alignItems: "center", justifyContent: "center"}}
              >
                <Stack direction={mobile ? "column" : "row"} spacing={2}>
                  <DatePicker label="Start Date" value={startDate} onChange={handleCustomStartDateChange} />
                  <DatePicker label="End Date" value={endDate} onChange={handleCustomEndDateChange} />
                </Stack>
                <ButtonGroup
                  color="primary"
                  aria-label="filter buttons"
                  orientation={mobile ? "vertical" : "horizontal"}
                >
                  {FILTER_BUTTONS.map(({name}) => (
                    <Button
                      key={name}
                      value={name}
                      onClick={handleTimeFilterButtonsChange}
                      variant={timeFilterButtons === name && !customDateSelected ? "contained" : "outlined"}
                    >
                      {name}
                    </Button>
                  ))}
                </ButtonGroup>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {
        //---------------------------------------------------------------------------
        // Render the loading circle
        //---------------------------------------------------------------------------
        loading && (
          <Box sx={{pb: 10}}>
            <TableLoading />
          </Box>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the Dashboard
        //---------------------------------------------------------------------------
        !loading && (
          <Grid container spacing={2} sx={{justifyContent: "space-evenly", alignItems: "stretch"}}>
            <Grid
              size={{xs: 6, sm: 4, lg: 3, xl: 2}}
              sx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
            >
              <BasicStatCard
                title="Inbox Items"
                value={inboxItemsDashboardData.numberOfInboxItems}
                tooltip="Number of unreviewed items in the Inbox"
                data-cy="number-of-active-inbox-items"
              />
            </Grid>
            {inboxItemsDashboardData.numberOfInboxItems > 0 && (
              <Grid size={{xs: 6, sm: 4, lg: 3, xl: 2}}>
                <BasicStatCard
                  title="Inbox Item Age"
                  value={inboxItemsDashboardData.averageInboxItemAge}
                  tooltip="Average age of items in the Inbox"
                  data-cy="average-age-of-inbox-items"
                />
              </Grid>
            )}
            <Grid size={{xs: 6, sm: 4, lg: 3, xl: 2}}>
              <BasicStatCard
                title="Studies Finalized"
                value={completedStudyCount}
                data-cy="total-finalized-studies"
                tooltip="Number of studies that were finalized during the time period"
              />
            </Grid>
            <Grid size={{xs: 6, sm: 4, lg: 3, xl: 2}}>
              <BasicStatCard
                title="Studies Started"
                value={newStudiesTotal}
                tooltip="Number of studies that were started during the time period"
                data-cy="total-started-studies"
              />
            </Grid>
            <Grid size={{xs: 6, sm: 4, lg: 3, xl: 2}}>
              <BasicStatCard
                title="Reports Sent"
                value={generatedReportCount}
                tooltip="Number of reports delivered to customers during the time period"
                data-cy="total-reports-generated"
              />
            </Grid>
            <Grid size={{xs: 6, sm: 4, lg: 3, xl: 2}}>
              <BasicStatCard
                title="Urgent Reports"
                value={urgentGeneratedReportCount}
                tooltip="Number of reports meeting MDN criteria that were delivered to customers during the time period"
                data-cy="total-urgent-reports"
              />
            </Grid>
            <Grid
              size={{xs: 12, lg: 9}}
              sx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
            >
              <StudiesOverTime
                title="Studies Started per Week"
                tooltip="Each point represents the number of studies started during the week starting on the Monday shown in the timestamp"
                data={newStudiesOverTime}
                colorPalette={dashboardPalette}
                id="studiesStartedTimeSeriesChart"
              />
            </Grid>
            <Grid
              size={{xs: 12, sm: 6, lg: 3}}
              sx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
            >
              <PieChartCard
                title="Studies Started by Type"
                data={newStudiesByType}
                colorPalette={dashboardPalette}
                id="startedStudiesTypesPie"
              />
            </Grid>
            <Grid
              size={{xs: 12, sm: 6, lg: 3}}
              sx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
            >
              <PieChartCard
                title="Studies Finalized by Type"
                data={completedStudies}
                colorPalette={dashboardPalette}
                id="finalizedStudiesTypesPie"
              />
            </Grid>
            <Grid
              size={{xs: 12, lg: 9}}
              sx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
            >
              <StudiesOverTime
                title="Studies Finalized per Week"
                tooltip="Each point represents the number of studies finalized during the week starting on the Monday shown in the timestamp"
                data={completedStudiesOverTime}
                colorPalette={dashboardPalette}
                id="studiesFinalizedTimeSeriesChart"
              />
            </Grid>
            <Grid
              size={12}
              sx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
            >
              <AverageStudyLengths data={averageStudyLengths} />
            </Grid>
          </Grid>
        )
      }
    </>
  );
}

export default Dashboard;
