/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import PageContext from "../../components/contexts/PageContext.jsx";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";
import DeviceActionsTopBar from "./DeviceActionsTopBar.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const DevicesHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./DevicesHeader.jsx"));
const DevicesRow = React.lazy(() => import(/* webpackPrefetch: true */ "./DevicesRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const defaultSort = {
  field: "updatedAt",
  reverse: true,
};
const fieldGetters = {
  facilityName: (device) => device.facility?.name,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowDevices(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "warehouse", "facilityAdmin"]);
}

function Devices() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [info, setInfo] = React.useState(null);

  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [devices, setDevices] = React.useState([]);
  const [checkedRows, setCheckedRows] = React.useState([]);

  const {isInAnyRole, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getDevices = React.useCallback(async () => {
    // if there are checkedDevices, don't reload the data
    if (checkedRows.length === 0) {
      try {
        const queryParams = {
          operationalState: {$ne: "terminated"},
          ...(!isInAnyRole(["tzAdmin", "warehouse"]) && {
            facilityId: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]},
          }),
        };

        const {data: devicesResponse} = await axios({
          method: "get",
          url: "/devices",
          params: queryParams,
        });

        setDevices(devicesResponse);
      } catch (err) {
        setError(err.message);
      }
    }
    setTableLoading(false);
  }, [checkedRows.length, isInAnyRole, userFacilityId]);

  useInterval(getDevices, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Top Bar Overlay Management
  //---------------------------------------------------------------------------
  const {setTopBarOverlay} = React.useContext(PageContext);
  React.useEffect(() => {
    if (checkedRows.length === 0) {
      setTopBarOverlay(null);
    } else {
      setTopBarOverlay(
        <DeviceActionsTopBar
          devices={devices}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          setTableReload={setTableLoading}
        />
      );
    }

    // Cleanup on unload
    return () => setTopBarOverlay(null);
  }, [checkedRows, setTopBarOverlay, devices]);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  const searchFields = React.useMemo(() => {
    const fields = {
      device: ["id", "barcode", "tzSerial"],
      status: "status",
      facility: [
        "facilityId",
        "pendingFacilityId",
        (object) => object.facility?.name,
        (object) => object.pendingFacility?.name,
      ],
      parent: [
        (object) => object.facility?.parentFacility?.name,
        (object) => object.facility?.parentFacility?.id,
      ],
      updated: "updatedAt",
      iccid: "iccid",
      hardware: "manufacturingSerial",
      http: (object) => object.deviceConnection?.lastHttpStatus,
      reply: (object) => object.deviceConnection?.lastResponseBody,
      patient: (object) => object.deviceConnection?.patientId,
      gtin: "gtin",
      forwarding: ["readableForwardingMode", "forwardingDestination"],
      firmware: "firmwareVersion",
      graphics: "graphicsVersion",
      pcb: "pcbPartNumber",
      imei: "imei",
      online: (object) => object.deviceConnection?.updatedAt,
      state: (object) => object.deviceConnection?.deviceState,
      is: {
        available: (object) =>
          object.availableForStudy &&
          (object.forwardingMode === "inboxForwarding" ||
            (object.facility?.forwardingMode === "inboxForwarding" &&
              object.forwardingMode === "useDefaultModeAndAddress")),
        transferred: (object) => object.operationalState === "transferred",
      },
      not: {
        available: (object) =>
          !object.availableForStudy &&
          (object.forwardingMode === "inboxForwarding" ||
            (object.facility?.forwardingMode === "inboxForwarding" &&
              object.forwardingMode === "useDefaultModeAndAddress")),
        transferred: (object) => object.operationalState !== "transferred",
      },
    };

    if (isInAnyRole(["tzAdmin", "warehouse"])) {
      fields.billed = [
        (object) => object.facility?.billingFacility?.name,
        (object) => object.facility?.billingFacility?.id,
      ];
    }

    return fields;
  }, [isInAnyRole]);

  const searchHelper = React.useMemo(() => {
    const helpers = [
      {label: "Has the words", keyword: "+", variant: "global"},
      {label: "Doesn't have", keyword: "-", variant: "global"},
      {label: "Device", keyword: "device", variant: "negatable"},
      {label: "Facility", keyword: "facility", variant: "negatable"},
      {label: "Parent Facility", keyword: "parent", variant: "negatable"},
      {label: "Device Status", keyword: "status", variant: "negatable"},
      {label: "Last Device State", keyword: "state", variant: "negatable"},
      {label: "Firmware Version", keyword: "firmware", variant: "relative"},
      {label: "Graphics Version", keyword: "graphics", variant: "relative"},
      {label: "Forwarding Destination", keyword: "forwarding", variant: "negatable"},
      {label: "Online since", keyword: "online", variant: "relativeDate"},
      {label: "Last HTTP code", keyword: "http", variant: "relative"},
      {label: "Last HTTP response body", keyword: "reply", variant: "negatable"},
      {label: "Available Devices", keyword: "is:available", variant: "toggle"},
      {label: "Transferred Devices", keyword: "is:transferred", variant: "toggle"},
      {label: "SIM Card ICCID", keyword: "iccid", variant: "negatable"},
      {label: "Device Hardware ID", keyword: "hardware", variant: "negatable"},
      {label: "Device GTIN", keyword: "gtin", variant: "negatable"},
      {label: "Updated At", keyword: "updated", variant: "relativeDate"},
    ];

    if (isInAnyRole(["tzAdmin", "warehouse"])) {
      helpers.splice(
        helpers.findIndex(({keyword}) => keyword === "parent"),
        0,
        {label: "Billed Facility", keyword: "billed", variant: "negatable"}
      );
    }

    return helpers;
  }, [isInAnyRole]);

  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields, searchHelper, searchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredDevices = useFilter(devices, search, searchFields);
  const [sortedDevices, handleSortSelection, sort] = useSort(filteredDevices, {defaultSort, fieldGetters});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageDevices = React.useMemo(
    () => sortedDevices.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedDevices]
  );
  React.useEffect(() => setPage(0), [search]);

  // Uncheck rows when navigating pagination or when navigating application
  React.useEffect(() => setCheckedRows([]), [page, location]);

  //---------------------------------------------------------------------------
  // Select All support
  //---------------------------------------------------------------------------
  const checkableRows = React.useMemo(() => {
    const deviceStatusTypes = [
      "No SIM Card",
      "Active - On Patient",
      "Active - Idle",
      "Active - Offline",
      "Suspended",
    ];

    return pageDevices.filter((device) => deviceStatusTypes.includes(device.status));
  }, [pageDevices]);

  const handleSelectPageOfDevices = React.useCallback(
    (checked) => setCheckedRows(checked ? checkableRows : []),
    [checkableRows]
  );

  //---------------------------------------------------------------------------
  // Selecting Devices Functions
  //---------------------------------------------------------------------------
  const handleSelectDevice = React.useCallback((event, device) => {
    setCheckedRows((prev) => {
      const checkedDevicesSet = new Set(prev);
      if (event.target.checked) {
        checkedDevicesSet.add(device);
      } else {
        checkedDevicesSet.delete(device);
      }
      return Array.from(checkedDevicesSet);
    });
  }, []);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowDevices(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Devices - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Alert message={info} setMessage={setInfo} level="info" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedDevices.length === 0 && <NoResults />
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedDevices.length > 0 && (
          <>
            <DevicesHeader
              sort={sort}
              setSort={handleSortSelection}
              sortedDevices={sortedDevices}
              checkedRows={checkedRows}
              checkableRowCount={checkableRows.length}
              handleSelectPageOfDevices={handleSelectPageOfDevices}
            />
            {pageDevices.map((device) => (
              <DevicesRow
                key={device.tzSerial}
                alwaysOpen={pageDevices.length === 1}
                checkedRows={checkedRows}
                device={device}
                devices={devices}
                handleSelectDevice={handleSelectDevice}
                setCheckedRows={setCheckedRows}
                setInfo={setInfo}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination
              pageSize={pageSize}
              page={page}
              setPage={setPage}
              count={sortedDevices.length}
              data-cy="displayed-devices-range"
            />
          </>
        )
      }
    </>
  );
}

export default Devices;
