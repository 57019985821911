/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const TerminatedHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./TerminatedHeader.jsx"));
const TerminatedRow = React.lazy(() => import(/* webpackPrefetch: true */ "./TerminatedRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  device: ["id", "barcode", "tzSerial"],
  updated: "updatedAt",
  iccid: "iccid",
  hardware: "manufacturingSerial",
  gtin: "gtin",
  pcb: "pcbPartNumber",
  imei: "imei",
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
  {label: "Device", keyword: "device", variant: "negatable"},
  {label: "SIM Card ICCID", keyword: "iccid", variant: "negatable"},
  {label: "Device Hardware ID", keyword: "hardware", variant: "negatable"},
  {label: "Device GTIN", keyword: "gtin", variant: "negatable"},
  {label: "Device Modem IMEI", keyword: "imei", variant: "negatable"},
  {label: "Device PCB PN", keyword: "pcb", variant: "negatable"},
  {label: "Updated At", keyword: "updated", variant: "relativeDate"},
];

const defaultSort = {
  field: "updatedAt",
  reverse: true,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowTerminated(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "warehouse"]);
}

function Terminated() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [terminatedDevices, setTerminatedDevices] = React.useState([]);
  const getTerminatedDevices = React.useCallback(async () => {
    try {
      const {data: terminatedDevicesResponse} = await axios({
        method: "get",
        url: "/devices",
        params: {operationalState: "terminated"},
      });

      setTerminatedDevices(terminatedDevicesResponse);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [setTerminatedDevices, setError, setTableLoading]);

  useInterval(getTerminatedDevices, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredDevices = useFilter(terminatedDevices, search, searchFields);
  const [sortedDevices, handleSortSelection, sort] = useSort(filteredDevices, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageDevices = React.useMemo(
    () => sortedDevices.slice(page * pageSize, (page + 1) * pageSize),
    [sortedDevices, page]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowTerminated(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Terminated - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedDevices.length === 0 && <NoResults />
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedDevices.length > 0 && (
          <>
            <TerminatedHeader sort={sort} setSort={handleSortSelection} />
            {pageDevices.map((device) => (
              <TerminatedRow
                key={device.id}
                terminatedDevice={device}
                alwaysOpen={pageDevices.length === 1}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedDevices.length} />
          </>
        )
      }
    </>
  );
}

export default Terminated;
